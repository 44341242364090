import React from "react";
import styled from "styled-components";
import Image from "../Image/Image";

const CategoryCardStyle = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    text-align: center;
  cursor: pointer;
  position:relative;
  &:hover {
    .image {
      
    }
  }
  .image {
    // margin-bottom: 18px;
    overflow: clip;
    border-radius: 7.5px;
    width: 100%;
    height:130px;
     border : 0.75px  solid #ae0000;
     padding:10px;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: clip;
      transition: all 0.3s;
      border-radius: 7.5px;
    }
  }
  .title {
       font-size: 15.25px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
  }
  &.text__in__image {
    border-radius: 6px;
    overflow: clip;
    .image {
      margin-bottom: 0;
      transition: all 0.3s;
    }
    .title {
      color: black;
    text-align: center;

    z-index: 2;
    padding-top: 10px;
    }
  }
  @media (max-width: 768px) {
    flex: 1;
    .title {
      font-size: 12px;
    }
    .image {
      margin-bottom: 10px;
    }
  }
`;

// font-size: 16.5px;
// font-weight: 600;

const CategoryCard = ({ name, link, bannerUrl, type, onClick }) => {
  return (
    <CategoryCardStyle
      className={type === "text-in-image" ? "text__in__image" : ""}
      onClick={onClick}
    >
      <div className="image">
        <Image src={bannerUrl} alt={name} />
        {type === "text-in-image" && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              zIndex: 1,
              // backgroundColor: "rgba(0,0,0,0.35)",
            }}
          />
        )}
      </div>
      <div className="title">{name}</div>
    </CategoryCardStyle>
  );
};

export default React.memo(CategoryCard);
