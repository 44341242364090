import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useRequest } from "../../../hooks/useRequest";
import { Skeleton } from "@mui/material";
import { getDate } from "../../../utils/helper";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import useDebounce from "../../../hooks/useDebounce";
import Sort from "../../../components/common/Sort";
import { priceSymbol } from "../../../utils/symbol";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import EditInstaCartModal from "../../../components/modals/EditInstaCartModal";

const PurchaseHistoryStyle = styled.div`
  flex: 1;
  > .subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #666666;
    margin: 12px 0;
  }
  .purchases__found {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 12px 0;
    span {
      font-weight: 700;
    }
  }
  .order__history__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th,
      td {
        padding: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        border: 1px solid #d2d1d1;
        width: 25%;
        text-transform: capitalize;
        
      }
      .MuiTablePagination-root {
        padding: 0;
        P {
          font-size: 13px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      th {
        font-size: 14px;
        font-weight: 600;
      },
      .action_td{
          display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    }
      .action_button{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ae0000;
    color: white;
    font-size: 13px;
      }
    }
    .table__data__row {
      cursor: pointer;
      &:hover {
        background-color: #ffeeee;
      }
    }
  }
`;


const sortingOptions = [
    // {
    //   label: "Select Option",
    //   value: "",
    // },
    // {
    //   label: "Order Id (Ascending)",
    //   value: "orderId",
    //   sortOrder: "asc",
    // },
    // {
    //   label: "Order Id (Descending)",
    //   value: "orderId",
    //   sortOrder: "desc",
    // },
    // {
    //   label: "Subtotal",
    //   value: "subtotal",
    // },
    // {
    //   label: "Total",
    //   value: "total",
    // },
    {
        label: "Group Buy",
        value: "groupBy",
    },
    {
        label: "Instabuild",
        value: "instabuild",
    },
    {
        label: "Order ID",
        value: "orderID",
    },
];

const InstabuildCarts = () => {
    const [getCartData, { isLoading }] = useRequest();
    const [deleteInstaCart, { state: instacarts }] = useRequest();
    const [pageNumber, setPageNumber] = useState(1);
    const [limitNumber, setLimitNumber] = useState(10);
    const [orderHistory, setOrderHistory] = useState([]);
    const navigate = useNavigate();

    const [isCreateModal, setIsCreateModal] = useState(false);
    const [cartDetails,setCartDetails] = useState(null)

    useDebounce(
        () => {
            pageNumber &&
                limitNumber &&
                fetchOrderSummary(
                    pageNumber,
                    limitNumber,
                );
        },
        [
            pageNumber,
            limitNumber,
        ],
        500
    );

    const fetchOrderSummary = async (
        pageNumber = 1,
        limitNumber = 10,
    ) => {
        const path = `/instacart?limit=${limitNumber}&page=${pageNumber}`
        const response = await getCartData({ path });
        if (response.success) {
            console.log(response)
            setOrderHistory(response?.data);
        }
    };
    const delCart = async (_id) => {
        const path = `/instacart/${_id}/delete`
        const response = await deleteInstaCart({ path , method:'DELETE' });
        if (response.success) {
            fetchOrderSummary()
        }
        toast.success(response.message);
    };

    const handlePageChange = (event, newPage) => {
        setPageNumber(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimitNumber(+event.target.value);
        setPageNumber(1);
    };




    const handleModal = (data) => { 
setIsCreateModal(true)
setCartDetails(data)
    }

    // useEffect(() => {
    //     if (instacarts?.data?.length) {
    //         const options = instacarts?.data
    //             ?.filter((item) => item?.isInstabuild)
    //             ?.map((item) => ({
    //                 label: item?.name,
    //                 value: item?._id,
    //             }));
    //         setProjectOptions((prev) => [
    //             {
    //                 label: "Select Option",
    //                 value: "",
    //             },
    //             ...options,
    //         ]);
    //     }
    // }, [instacarts]);

    return (
        <>
        
        <PurchaseHistoryStyle>
            <p className="subtitle">Instabuild Cart</p>

            {/* {!isLoading && (
                <div className="purchases__found">
                    {orderHistory?.totalDocs ? (
                        <span>{orderHistory?.totalDocs}</span>
                    ) : (
                        "No"
                    )}{" "}
                    No Cart Found
                </div>
            )} */}
            <table className="order__history__table">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Item(s)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <>
                            {Array.from({ length: limitNumber }, (_, index) => index + 1).map(
                                (item) => (
                                    <tr key={item}>
                                        {Array.from({ length: 4 }, (_, index) => index + 1).map(
                                            (item2) => (
                                                <td key={item2}>
                                                    <Skeleton height={24} variant="rectangular" />
                                                </td>
                                            )
                                        )}
                                    </tr>
                                )
                            )}
                        </>
                    ) : (
                        <>
                            {orderHistory?.map((item, index) => (
                                <tr
                                    className="table__data__row"
                                    key={item?._id}
                                >
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.items?.length}</td>
                                    <td className="action_td">
                                        <button onClick={()=>handleModal(item)} className="action_button">
                                            <MdEdit />
                                        </button>
                                        <button onClick={()=>delCart(item._id)} className="action_button">
                                            <MdOutlineDelete />
                                        </button>
                                    </td>
                                    {/* <td>{item?.shippingAddress}</td>
                  <td>{item?.jobName}</td>
                  <td>{item?.orderStatus}</td>
                  <td className="payment__ref">
                    {item?.paymentRefNumber || "N/A"}
                  </td>
                  <td>{item?.paidStatus}</td>
                  <td>
                    {priceSymbol +
                      item?.subtotal?.toFixed(2)}
                  </td>
                  <td>
                    {priceSymbol +
                      item?.total?.toFixed(2)}
                  </td> */}
                                </tr>
                            ))}
                        </>
                    )}
                    <tr>
                        <TablePagination
                            rowsPerPage={limitNumber}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 100]}
                            page={pageNumber - 1}
                            count={orderHistory}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </tr>
                </tbody>
            </table>
        </PurchaseHistoryStyle>

        {isCreateModal && (
        <EditInstaCartModal cartDetails={cartDetails} getData={fetchOrderSummary} handleClose={() => setIsCreateModal(false)} />
      )}

        </>
    );
};

export default InstabuildCarts;
