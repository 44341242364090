import React from "react";
import styled from "styled-components";
import { ReactComponent as GearIcon } from "../../../assets/gear.svg";

const ProductDimensionsStyle = styled.div`
  display: grid;
  grid-template-columns:repeat(4,1fr);
  gap: 25px;
  .product__specification {
    width: 100%;
    display: flex;
    gap: 10px;
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eeeeee;
    }
    .content {
      p {
        color: #959595;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .main {
        color: #000;
        margin-top: 3.8px;
      }
    }

    @media screen and (max-width:1200px){
    grid-template-columns:repeat(3,1fr);
    }
    @media screen and (max-width:700px){
    grid-template-columns:repeat(2,1fr);
    }
    @media screen and (max-width:450px){
    grid-template-columns:repeat(1,1fr);
    }
  }
`;

const ProductDimensions = ({ dimensions, isDimensions }) => {
    return (
        <ProductDimensionsStyle>
            {isDimensions && dimensions?.map((item) => (
                <div className="product__specification">
                    <div className="icon">
                        <GearIcon />
                    </div>
                    <div className="content">
                        <p>{item?.name}</p>
                        <p className="main">{item?.value}</p>
                    </div>
                </div>
            ))}
            {!isDimensions && (
                <div className="product__specification">
                    <div className="content">
                        <p>No dimensions are applicable.</p>
                    </div>
                </div>
            )}
        </ProductDimensionsStyle>
    );
};

export default ProductDimensions;
