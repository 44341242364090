import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../context/useAppContext";
import { priceSymbol } from "../../utils/symbol";
import { useRequest } from "../../hooks/useRequest";
import { useNavigate } from "react-router-dom";

const CheckoutOrderSummaryStyle = styled.div`
  padding: 30px;
  border-radius: 11.25px;
  border: 0.75px solid #d9d9d9;
  background: #fff;
  .checkout__order__summary__item {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding-top: 20px;
    border-top: 0.75px solid rgba(48, 48, 48, 0.25);
    margin-top: 20px;
    &:first-child {
      margin-top: 35px;
      border-top: 0px;
      padding-top: 0px;
    }
  }
  .checkout__order__summary__title {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .checkout__order__summary__item__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #303030;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-transform: capitalize;
    overflow: hidden;
    flex: 1;
    &.flex {
      display: flex;
      gap: 4px;
    }
  }
  .checkout__order__summary__item__quantity,
  .checkout__order__summary__item__totals {
    color: #303030;
    font-size: 15px;
    font-weight: 700;
    line-height: 22.5px;
    flex: 0 0 80px;
    text-align: right;
  }
  .checkout__order__summary__item__quantity {
    flex: 0 0 30px;
    text-align: center;
  }
  .checkout__order__summary__handler {
    margin-top: 10px;
    padding-top: 15px;
    border-top: 0.75px solid rgba(48, 48, 48, 0.25);
    margin-top: 35px;
    .checkout__order__summary__subtotals {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .checkout__items__total__title {
        color: #303030;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        &.bold {
          font-weight: 700;
        }
      }
    }
    .checkout__now__button {
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 23px;
      border-radius: 5px;
      width: 100%;
      height: 50px;
      background: #ae0000;
      margin-top: 20px;
      &:disabled {
        background: #a7a7a7;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 15px;
    .checkout__order__summary__title {
      font-size: 16px;
    }
    .checkout__order__summary__item__list {
      padding-top: 8px;
    }
    .checkout__order__summary__item {
      margin-top: 12px;
      gap: 24px;
    }
    .checkout__order__summary__item__title {
      font-size: 14px;
    }
    .checkout__order__summary__item__quantity,
    .checkout__order__summary__item__totals {
      font-size: 14px;
      font-weight: 600;
    }
    .checkout__order__summary__handler .checkout__now__button {
      width: 100%;
      font-size: 14px;
      height: 42px;
    }
  }
`;

const CheckoutOrderSummary = ({
  orderSummaryData,
  handleOrderNow,
  checkoutAddress,
  isCheckoutDisabled,
  caRateService,
  dt,
  billingType
}) => {
  const { checkoutCartData, appliedCoupon } = useAppContext();
  const navigate = useNavigate()

  const [handleRequest] = useRequest()

  const deliveryPartner = ['inhouse', 'internal shipping', 'Canada Post', 'Fedex'];
  const onlinePayPartner = ['Canada Post', 'Fedex'];


  const handleOrderDraftNow = async () => {
    try {
      const path = `/order/create/draft`;
      const response = await handleRequest({
        path,
        method: "POST",
        body: JSON.stringify({
          cartId: checkoutCartData._id,
          billingAddress: checkoutAddress?.billing,
          shippingAddress: checkoutAddress?.shipping,
          deliveryPartner: deliveryPartner[dt],
        }),
      });

      navigate('/thank-you')
      // toast.success("Success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CheckoutOrderSummaryStyle>
      <h3 className="checkout__order__summary__title">Order Summary</h3>
      <div className="checkout__order__summary__item__list">
        {checkoutCartData?.items?.length &&
          checkoutCartData?.items?.map((item) => {
            if (item?.quantity > 0) {
              return (
                <div className="checkout__order__summary__item" key={item?._id}>
                  <p className="checkout__order__summary__item__title">
                    {item?.product?.name}
                  </p>
                  <h4 className="checkout__order__summary__item__quantity">
                    x{item?.quantity}
                  </h4>
                  <h4 className="checkout__order__summary__item__totals">
                    {priceSymbol}
                    {Number((item?.product?.price - item?.product?.discount) *
                      item?.quantity)?.toFixed(2)}
                  </h4>
                </div>
              );
            }
            return;
          })}
      </div>
      <div className="checkout__order__summary__handler">
        <div className="checkout__order__summary__subtotals">
          <div className="checkout__items__total__title">Subtotal</div>
          <div className="checkout__order__summary__item__totals">
            {priceSymbol}
            {orderSummaryData?.subtotal?.toFixed(2)}
          </div>
        </div>
        {orderSummaryData?.discountAmount > 0 && appliedCoupon && (
          <div className="checkout__order__summary__subtotals">
            <div className="checkout__items__total__title">
              Discount
              <span style={{ fontSize: "10px", color: "#4caf50" }}>
                ( {appliedCoupon} )
              </span>
            </div>
            <div
              className="checkout__order__summary__item__totals"
              style={{ color: "rgb(76, 175, 80)" }}
            >
              {priceSymbol}
              {orderSummaryData?.discountAmount?.toFixed(2)}
            </div>
          </div>
        )}

        {billingType !== 'pickup' && 
        <div className="checkout__order__summary__subtotals">
          <div className="checkout__items__total__title">Shipping Fee</div>
          <div className="checkout__order__summary__item__totals">
            {priceSymbol}
            {orderSummaryData?.shippingCharges?.toFixed(2)}
          </div>
        </div>
}
        {/* <i style={{ fontSize: "12px", color: "#211f1f", fontWeight: 500, marginBottom: 14, display: 'block' }}>
          ( Shipping Fee will be confirmed within 30 minutes after Order Confirmation )
        </i> */}
        <div className="checkout__order__summary__subtotals">
          <div className="checkout__items__total__title">GST Tax</div>
          <div className="checkout__order__summary__item__totals">
            {priceSymbol}
            {orderSummaryData?.gst?.toFixed(2)}
            {/* {orderSummaryData?.taxAmount} */}
          </div>
        </div>
        <div className="checkout__order__summary__subtotals">
          <div className="checkout__items__total__title">PST Tax</div>
          <div className="checkout__order__summary__item__totals">
            {priceSymbol}
            {orderSummaryData?.pst?.toFixed(2)}
            {/* {orderSummaryData?.taxAmount} */}
          </div>
        </div>
        <div className="checkout__order__summary__subtotals">
          <div className="checkout__items__total__title bold">Total</div>
          <div className="checkout__order__summary__item__totals">
            {priceSymbol}
            {orderSummaryData?.totalAmount?.toFixed(2)}
          </div>
        </div>


        {
          (billingType === 'pickup') || (onlinePayPartner.indexOf(deliveryPartner[dt]) >= 0) ? (
            <button
              className="checkout__now__button"
              disabled={isCheckoutDisabled}
              onClick={handleOrderNow}
            >
              Order Now
            </button>
          ) :
            (
              <button
                className="checkout__now__button"
                disabled={isCheckoutDisabled}
                onClick={handleOrderDraftNow}
              >
                Order Draft
              </button>
            )
        }
      </div>
      {billingType !== 'pickup' && deliveryPartner[dt] !== 'Canada Post' && deliveryPartner[dt] !== 'Fedex' && <strong style={{ fontSize: "14px", color: "#211f1f", fontWeight: 700, marginTop: 14, display: 'block' }}>
        Remark* : Your order will be placed in order drafts, where you can pay and finalize it after the shipping costs have been updated.
      </strong>}
    </CheckoutOrderSummaryStyle>
  );
};

export default CheckoutOrderSummary;
